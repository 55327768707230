<template>
  <div class="hidden">
    <vs-sidebar
        ref="sidebar"
        v-model="active"
        :reduce="$store.state.sideBarCollapse"
        open
    >
      <template #logo v-if="!$store.state.sideBarCollapse">
        <img src="../assets/logistic.png"/>
        <h4
            :style="{ marginLeft: '10px', fontSize: '18px', fontWeight: 'bold' }"
        >
          {{ $t("Sidebar.ຮຸ່ງອາລຸນ ຂົນສົ່ງດວ່ນ") }}
        </h4>
      </template>

      <!-- ProvinceManager -->
      <vs-sidebar-group v-if="$can(['province-manager'])">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-map"></i>
            </template>
            {{ $t("Sidebar.ຫົວໜ້າແຂວງ") }}
          </vs-sidebar-item>
        </template>

        <div v-if="$can(['province-manager'])">
          <vs-sidebar-item
              id="tab.shipment.province.manager"
              ref="tab.shipment.province.manager"
              :value="JSON.stringify({ query: { tab: 'all' } })"
          >
            <template #icon>
              <i class="fal fa-list-alt"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນລາຍການ orders") }}
          </vs-sidebar-item>
        </div>

        <div v-if="$can(['province-manager'])">
          <vs-sidebar-item
              id="tab.solve.shipments"
              ref="tab.solve.shipments"
              :value="JSON.stringify({ query: { tab: 'all' } })"
          >
            <template #icon>
              <i class="fal fa-engine-warning"></i>
            </template>
            {{ $t("Sidebar.ຄົ້ນຫາບິນ") }}
          </vs-sidebar-item>
        </div>
        <div v-if="$can(['province-manager'])">
          <vs-sidebar-item id="print.all.shipment">
            <template #icon>
              <i class="fal fa-print"></i>
            </template>
            {{ $t("Sidebar.ລາຍການບິນ") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>
      <!-- ProvinceManager -->

      <vs-sidebar-group v-if="$can(['SuperAdmin', 'manager', 'news'])">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-tasks-alt"></i>
            </template>
            {{ $t("Sidebar.ຈັດການຂໍ້ມູນຫຼັກ") }}
          </vs-sidebar-item>
        </template>

        <div v-if="$can(['manager', 'SuperAdmin'])">
          <vs-sidebar-item id="list.dashboard">
            <template #icon>
              <i class="fal fa-tachometer-slow"></i>
            </template>
            {{ $t("Sidebar.ໜ້າຫຼັກ") }}
          </vs-sidebar-item>
        </div>

        <div v-if="$can('SuperAdmin')">
          <vs-sidebar-item id="list.role.users">
            <template #icon>
              <i class="fal fa-user-tag"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນ Roles") }}
          </vs-sidebar-item>
        </div>

        <div v-if="$can('SuperAdmin')">
          <vs-sidebar-item id="list.permission.users">
            <template #icon>
              <i class="fal fa-users-class"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນ Permissions") }}
          </vs-sidebar-item>
        </div>

        <div v-if="$can('SuperAdmin')">
          <vs-sidebar-item id="list.package.size">
            <template #icon>
              <i class="fal fa-box-full"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນ ປະເພດຂະໜາດເເພ໋ກເເກ໋ດ") }}
          </vs-sidebar-item>
        </div>

        <div v-if="$can('SuperAdmin')">
          <vs-sidebar-item
              id="tab.list.packages"
              ref="tab.list.packages"
              :value="JSON.stringify({ params: { type: 'all' } })"
          >
            <template #icon>
              <i class="fal fa-box-full"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນ ເເພ໋ກເເກ໋ດ") }}
          </vs-sidebar-item>
        </div>

        <div v-if="$can('SuperAdmin')">
          <vs-sidebar-item id="tab.list.promotion">
            <template #icon>
              <i class="fal fa-tags"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນ ໂປຮໂມຮຊັ່ນ") }}
          </vs-sidebar-item>
        </div>

        <div v-if="$can('SuperAdmin')">
          <vs-sidebar-item id="tab.list.coupon">
            <template #icon>
              <i class="fal fa-badge-dollar"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນ ຄູປ໋ອງ") }}
          </vs-sidebar-item>
        </div>

        <div v-if="$can('SuperAdmin')">
          <vs-sidebar-item id="list.villages">
            <template #icon>
              <i class="fal fa-location-circle"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນ ບ້ານທັງໝົດ") }}
          </vs-sidebar-item>
        </div>

        <div v-if="$can('SuperAdmin')">
          <vs-sidebar-item id="list.banners">
            <template #icon>
              <i class="fal fa-images"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນ ເເບນເນີ ( Banners )") }}
          </vs-sidebar-item>
        </div>

        <div v-if="$can('SuperAdmin')">
          <vs-sidebar-item id="list.parcel-categories">
            <template #icon>
              <i class="fal fa-file-certificate"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນ ໝວດໝູ່ພັດສະດຸ") }}
          </vs-sidebar-item>
        </div>

        <div v-if="$can('SuperAdmin')">
          <vs-sidebar-item id="list.shipment.status">
            <template #icon>
              <i class="fal fa-journal-whills"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນ ສະຖານະການຈັດສົ່ງ") }}
          </vs-sidebar-item>
        </div>

        <div v-if="$can(['SuperAdmin', 'news'])">
          <vs-sidebar-item id="list.news">
            <template #icon>
              <i class="fal fa-newspaper"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນ ຂ່າວສານທົ່ວໄປ") }}
          </vs-sidebar-item>
        </div>

        <div v-if="$can('SuperAdmin')">
          <vs-sidebar-item id="list.contact-us">
            <template #icon>
              <i class="fal fa-phone-rotary"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນ ຕິດຕໍ່ພວກເຮົາ") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>

      <vs-sidebar-group
          v-if="
          $can('SuperAdmin') ||
          $can('callcentervipcustomer') ||
          $can('accountingmanager') ||
          $can('accountingcod') ||
          $can('manager')
        "
      >
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="far fa-address-card"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນລູກຄ້າ") }}
          </vs-sidebar-item>
        </template>

        <div
            v-if="$can('SuperAdmin') || $can('accountingcod') || $can('manager')"
        >
          <vs-sidebar-item
              id="tab.general.customer"
              ref="tab.general.customer"
              :value="JSON.stringify({ query: { tab: 'general' } })"
          >
            <template #icon>
              <i class="fal fa-user-alt"></i>
            </template>
            {{ $t("Sidebar.ລູກຄ້າທົ່ວໄປ") }}
          </vs-sidebar-item>
        </div>

        <div
            v-if="
            $can('SuperAdmin') ||
            $can('callcentervipcustomer') ||
            $can('accountingcod') ||
            $can('manager')
          "
        >
          <vs-sidebar-item
              id="tab.vip.customer"
              ref="tab.vip.customer"
              :value="JSON.stringify({ query: { tab: 'pending' } })"
          >
            <template #icon>
              <i class="fal fa-user-tie"></i>
            </template>
            {{ $t("Sidebar.ລູກຄ້າ VIP") }}
          </vs-sidebar-item>
        </div>

        <div v-if="$can(['SuperAdmin', 'accountingmanager', 'manager'])">
          <vs-sidebar-item
              id="tab.company.customer"
              ref="tab.company.customer"
              :value="JSON.stringify({ query: { tab: 'company' } })"
          >
            <template #icon>
              <i class="fal fa-building"></i>
            </template>
            {{ $t("Sidebar.ລູກຄ້າບໍລິສັດ") }}
          </vs-sidebar-item>
        </div>

        <div v-if="$can('SuperAdmin')">
          <vs-sidebar-item id="list.celebrate">
            <template #icon>
              <i class="fal fa-users"></i>
            </template>
            {{ $t("Sidebar.ລູກຄ້າ ວັນເປີດງານ") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>

      <vs-sidebar-group v-if="$can('SuperAdmin')">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-user-circle"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນພະນັກງານ") }}
          </vs-sidebar-item>
        </template>

        <div>
          <vs-sidebar-item id="list.branch.users">
            <template #icon>
              <i class="fal fa-user-alt"></i>
            </template>
            {{ $t("Sidebar.ພະນັກງານ ສາຂາ") }}
          </vs-sidebar-item>
        </div>
        <div>
          <vs-sidebar-item id="list.distribution.center.users">
            <template #icon>
              <i class="fal fa-user-alt"></i>
            </template>
            {{ $t("Sidebar.ພະນັກງານ ສູນຄັດເເຍກ") }}
          </vs-sidebar-item>
        </div>
        <div>
          <vs-sidebar-item id="list.delivery.user">
            <template #icon>
              <i class="fal fa-user-alt"></i>
            </template>
            {{ $t("Sidebar.ພະນັກງານ ( Delivery )") }}
          </vs-sidebar-item>
        </div>
        <div>
          <vs-sidebar-item id="list.call.center.users">
            <template #icon>
              <i class="fal fa-user-alt"></i>
            </template>
            {{ $t("Sidebar.ພະນັກງານ ( Call Center )") }}
          </vs-sidebar-item>
        </div>
        <div>
          <vs-sidebar-item id="list.user.vehicle.departments">
            <template #icon>
              <i class="fal fa-user-alt"></i>
            </template>
            {{ $t("Sidebar.ພະນັກງານ ພາຫະນະ") }}
          </vs-sidebar-item>
        </div>
        <div>
          <vs-sidebar-item id="list.accounting.user">
            <template #icon>
              <i class="fal fa-user-alt"></i>
            </template>
            {{ $t("Sidebar.ພະນັກງານ ບັນຊີ") }}
          </vs-sidebar-item>
        </div>
        <div>
          <vs-sidebar-item id="list.employee">
            <template #icon>
              <i class="fal fa-user-alt"></i>
            </template>
            {{ $t("Sidebar.ຜູ້ໃຊ້ອື່ນໆ") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>

      <vs-sidebar-group v-if="$can(['SuperAdmin', 'service'])">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-registered"></i>
            </template>
            {{ $t("Sidebar.ລົງທະບຽນ") }}
          </vs-sidebar-item>
        </template>

        <div>
          <vs-sidebar-item
              id="list.branches"
              ref="list.branches"
              :value="JSON.stringify({ params: { type: 'all' } })"
          >
            <template #icon>
              <i class="fal fa-users"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນ ສາຂາພັດສະດຸ") }}
          </vs-sidebar-item>
        </div>
        <div v-if="$can(['SuperAdmin'])">
          <vs-sidebar-item id="list.distribution.center">
            <template #icon>
              <i class="fal fa-users"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນ ສາຂາສາງຄັດເເຍກພັດສະດຸ") }}
          </vs-sidebar-item>
        </div>

        <div v-if="$can(['SuperAdmin'])">
          <vs-sidebar-item id="list.deliveries">
            <template #icon>
              <i class="fal fa-motorcycle"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນ ສາຂາ Delivery") }}
          </vs-sidebar-item>
        </div>

        <div v-if="$can(['SuperAdmin'])">
          <vs-sidebar-item id="list.call.centers">
            <template #icon>
              <i class="fal fa-phone-rotary"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນ Call-Center") }}
          </vs-sidebar-item>
        </div>

        <div v-if="$can(['SuperAdmin'])">
          <vs-sidebar-item id="list.vehicle.department">
            <template #icon>
              <i class="fal fa-truck-container"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນ ພະເເນກພາຫະນະ") }}
          </vs-sidebar-item>
        </div>
        <div v-if="$can(['SuperAdmin'])">
          <vs-sidebar-item id="list.post_boxes">
            <template #icon>
              <i class="fal fa-boxes"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນ ກ່ອງໄປສະນີ") }}
          </vs-sidebar-item>
        </div>
        <div v-if="$can(['SuperAdmin'])">
          <vs-sidebar-item id="route.system.index">
            <template #icon>
              <i class="fal fa-truck-container"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນ ສາຍທາງ") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>

      <vs-sidebar-group v-if="$can('deliverymanager')">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-motorcycle"></i>
            </template>
            {{ $t("Sidebar.order ຂອງລູກຄ້າ") }}
          </vs-sidebar-item>
        </template>
        <div>
          <vs-sidebar-item id="delivery.tab.order.request">
            <template #icon>
              <i class="fal fa-gift"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນ ລາຍການ orders") }}
          </vs-sidebar-item>
        </div>
        <div>
          <vs-sidebar-item id="delivery.list.reports">
            <template #icon>
              <i class="fal fa-chart-line"></i>
            </template>
            {{ $t("Sidebar.ລາຍງານ ລາຍການ orders") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>

      <vs-sidebar-group v-if="$can(['branchmanager', 'franchise-manager'])">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-dolly-flatbed-alt"></i>
            </template>
            {{ $t("Sidebar.ລາຍການພັດສະດຸ ຂອງສາຂາ") }}
          </vs-sidebar-item>
        </template>
        <div>
          <vs-sidebar-item
              id="branch.tab.list.order"
              ref="branch.tab.list.order"
              :value="JSON.stringify({ query: { tab: 'all' } })"
          >
            <template #icon>
              <i class="fal fa-arrow-alt-circle-left"></i>
            </template>
            {{ $t("Sidebar.ລາຍການສ້າງ orders") }}
          </vs-sidebar-item>
          <vs-sidebar-item
              id="branch.tab.list.order.inbound"
              ref="branch.tab.list.order.inbound"
              :value="JSON.stringify({ query: { tab: 'all' } })"
              v-if="!$can('organization-manager')"
          >
            <template #icon>
              <i class="fal fa-arrow-alt-circle-right"></i>
            </template>
            {{ $t("Sidebar.ລາຍການຮັບ orders") }}
          </vs-sidebar-item>
          <div>
            <vs-sidebar-item v-if="$can(isBranchCLogisticsAccounting())"
                             id="account.tab.solve.shipments"
                             ref="account.tab.solve.shipments"
                             :value="JSON.stringify({ query: { tab: tabQueryShipment } })">
              <template #icon>
                <i class="fal fa-engine-warning"></i>
              </template>
              {{ $t("Sidebar.ຄົ້ນຫາບິນ") }}
            </vs-sidebar-item>
          </div>
          <!--          <vs-sidebar-item id="list.sign.cdc">-->
          <!--            <template #icon>-->
          <!--              <i class='fal fa-pen'></i>-->
          <!--            </template>-->
          <!--            ລາຍການຍິງເຊັນຮັບ CDC-->
          <!--          </vs-sidebar-item>-->
          <vs-sidebar-item id="list.sign.cod" v-if="$i18n.locale !== 'CN' && !$can('organization-manager')">
            <template #icon>
              <i class="fal fa-money-bill"></i>
            </template>
            {{ $t("Sidebar.ລາຍການຍິງເຊັນຮັບ COD") }}
          </vs-sidebar-item>
          <vs-sidebar-item id="list.branch.summery" v-if="$i18n.locale !== 'CN'">
            <template #icon>
              <i class="fal fa-chart-pie"></i>
            </template>
            {{ $t("Sidebar.ຍອດສາຂາ") }}
          </vs-sidebar-item>
          <vs-sidebar-item
              id="tab.invoice.branch"
              ref="tab.invoice.branch"
              :value="JSON.stringify({ query: { tab: 'origin' } })"
          >
            <template #icon>
              <i class="fal fa-money-check-edit"></i>
            </template>
            {{ $t("Sidebar.ອິນວອຍສາຂາ") }}
          </vs-sidebar-item>
          <vs-sidebar-item id="branch.payment" v-if="!$can('organization-manager')">
            <template #icon>
              <i class="fal fa-coin"></i>
            </template>
            {{ $t("Sidebar.ລາຍການພ້ອຍ") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>

      <vs-sidebar-group v-if="$can('accountmanager')">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-dolly-flatbed-alt"></i>
            </template>
            {{ $t("Sidebar.ລາຍການ Order ພັດສະດຸ") }}
          </vs-sidebar-item>
        </template>
        <div>
          <vs-sidebar-item
              id="account.tab.list.order"
              ref="account.tab.list.order"
              :value="JSON.stringify({ query: { tab: 'origin_freight' } })"
          >
            <template #icon>
              <i class="fal fa-gift"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນ ລາຍການ orders") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>

      <!-- SuperAdmin -->
      <vs-sidebar-group
          v-if="
          $can([
            'SuperAdmin',
            'callcentervipcustomer',
            'service',
            'manager',
            'marketing',
            'suburbMarketing',
          ])
        "
      >
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-dolly-flatbed-alt"></i>
            </template>
            {{ $t("Sidebar.ລາຍການ Order ພັດສະດຸ") }}
          </vs-sidebar-item>
        </template>

        <div
            v-if="$can(['service', 'manager', 'marketing', 'suburbMarketing'])"
        >
          <vs-sidebar-item
              id="tab.all.shipments"
              ref="tab.all.shipments"
              :value="JSON.stringify({ query: { tab: 'all' } })"
          >
            <template #icon>
              <i class="fal fa-list-alt"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນ ລາຍການ orders") }}
          </vs-sidebar-item>
        </div>

        <div
            v-if="
            $can([
              'callcentervipcustomer',
              'manager',
              'marketing',
              'suburbMarketing',
            ])
          "
        >
          <vs-sidebar-item
              id="tab.solve.shipments"
              ref="tab.solve.shipments"
              :value="JSON.stringify({ query: { tab: 'all' } })"
          >
            <template #icon>
              <i class="fal fa-engine-warning"></i>
            </template>
            {{ $t("Sidebar.ຄົ້ນຫາບິນ") }}
          </vs-sidebar-item>
        </div>
        <div v-if="$can(['service', 'SuperAdmin'])">
          <vs-sidebar-item id="account.tab.solve.shipments" ref="account.tab.solve.shipments"
                           :value="JSON.stringify({query: { tab: 'all' } })">
            <template #icon>
              <i class='fal fa-engine-warning'></i>
            </template>
            ຄົ້ນຫາບິນ
          </vs-sidebar-item>
        </div>
        <!--        <div v-if="$can('SuperAdmin')">-->
        <!--          <vs-sidebar-item-->
        <!--              id="account.tab.solve.shipments"-->
        <!--              ref="account.tab.solve.shipments"-->
        <!--              :value="JSON.stringify({ query: { tab: 'origin_freight_fees' } })"-->
        <!--          >-->
        <!--            <template #icon>-->
        <!--              <i class="fal fa-key"></i>-->
        <!--            </template>-->
        <!--            {{ $t("Sidebar.ແກ້ໄຂບິນ") }}-->
        <!--          </vs-sidebar-item>-->
        <!--        </div>-->

        <!--        report-->
        <!--        <div v-if="$can(['SuperAdmin','accountingmanager'])">-->
        <!--          <vs-sidebar-item id="tab.report.driver" ref="tab.report.driver"-->
        <!--                           :value="JSON.stringify({query: { tab: 'all' } })">-->
        <!--            <template #icon>-->
        <!--              <i class='fal fa-truck'></i>-->
        <!--            </template>-->
        <!--            ລາຍການອໍເດີໂຊເພີລົດໃຫຍ່-->
        <!--          </vs-sidebar-item>-->
        <!--        </div>-->

        <!--        <div v-if="$can(['SuperAdmin','accountingmanager'])">-->
        <!--          <vs-sidebar-item id="tab.report.distribution" ref="tab.report.distribution"-->
        <!--                           :value="JSON.stringify({query: { tab: 'all' } })">-->
        <!--            <template #icon>-->
        <!--              <i class='fal fa-house'></i>-->
        <!--            </template>-->
        <!--            ລາຍການຍິງເຄື່ອງເຂົ້າ-ອອກ ສູນຄັດແຍກ-->
        <!--          </vs-sidebar-item>-->
        <!--        </div>-->

        <div v-if="$can('SuperAdmin')">
          <vs-sidebar-item
              id="tab.all.shipment.pre.order"
              ref="tab.all.shipment.pre.order"
              :value="JSON.stringify({ query: { tab: 'origin_freight_fees' } })"
          >
            <template #icon>
              <i class="fal fa-list"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນ ລາຍການ Pre-Order") }}
          </vs-sidebar-item>
        </div>

        <div v-if="$can(['SuperAdmin'])">
          <vs-sidebar-item
              id="list.report.order.request"
              ref="list.report.order.request"
              :value="JSON.stringify({ query: { tab: 'all' } })"
          >
            <template #icon>
              <i class="fal fa-comment-alt-lines"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນ ລາຍການເອີ້ນຮັບເຄື່ອງ") }}
          </vs-sidebar-item>
        </div>

        <div v-if="$can(['SuperAdmin', 'service', 'callcentervipcustomer'])">
          <vs-sidebar-item
              id="shipment-issue-stock"
              ref="shipment-issue-stock"
              :value="JSON.stringify({})"
          >
            <template #icon>
              <i class="fas fa-tasks"></i>
            </template>
            {{ $t("Sidebar.ກວດສອບພັດສະດຸ") }}
          </vs-sidebar-item>
        </div>

        <div v-if="$can(['SuperAdmin','service', 'callcentervipcustomer'])">
          <vs-sidebar-item
              id="parcel.arrived.index"
              ref="parcel.arrived.index"
              :value="JSON.stringify({})"
          >
            <template #icon>
              <i class="fas fa-tasks"></i>
            </template>
            ກວດເຄື່ອງຮອດສາຂາ
          </vs-sidebar-item>
        </div>


      </vs-sidebar-group>
      <!-- End SuperAdmin -->

      <!-- DistributionCenters -->
      <vs-sidebar-group
          v-if="
          $can([
            'SuperAdmin',
            'distributioncentermanager',
            'distribution',
            'service',
          ])
        "
      >
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-house-day"></i>
            </template>
            {{ $t("Sidebar.ລາຍການພັດສະດຸ ຂອງສູນຄັດແຍກ") }}
          </vs-sidebar-item>
        </template>
        <div v-if="$can(['distributioncentermanager'])">
          <vs-sidebar-item
              id="distribution.center.tab.all.shipments"
              ref="distribution.center.tab.all.shipments"
              :value="JSON.stringify({ query: { tab: 'all' } })"
          >
            <template #icon>
              <i class="fal fa-house"></i>
            </template>
            {{ $t("Sidebar.ລາຍການຍິງເຄື່ອງເຂົ້າ - ອອກ") }}
          </vs-sidebar-item>
        </div>
        <div
            v-if="
            $can([
              'SuperAdmin',
              'distributioncentermanager',
              'distribution',
              'service',
            ])
          "
        >
          <vs-sidebar-item id="distribution.center.shipment.bag">
            <template #icon>
              <i class="fal fa-gift"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນ ລາຍການກະເປົ໋າ") }}
          </vs-sidebar-item>
          <vs-sidebar-item id="distribution.center.shipment.bag.reusable">
            <template #icon>
              <i class="fas fa-recycle"></i>
            </template>
            {{ $t("Sidebar.ລາຍການກະເປົ໋າຫມຸນໃຊ້ໄດ້") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>
      <!-- End DistributionCenters -->

      <vs-sidebar-group v-if="$can(['accountingcod'])">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-user"></i>
            </template>
            {{ $t("Sidebar.ລາຍການອຸນຸມັດລູກຄ້າ COD Auto") }}
          </vs-sidebar-item>
        </template>
        <div v-if="$can(['accountingcod'])">
          <vs-sidebar-item id="tab.auto.approved.cod">
            <template #icon>
              <i class="fal fa-list"></i>
            </template>
            {{ $t("Sidebar.ລາຍການລູກຄ້າ COD") }}
          </vs-sidebar-item>
        </div>
        <div v-if="$can(['accountingcod'])">
          <vs-sidebar-item id="list.account.bank">
            <template #icon>
              <i class="fal fa-piggy-bank"></i>
            </template>
            {{ $t("Sidebar.ລາຍການບັນຊີລູກຄ້າ") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>

      <vs-sidebar-group v-if="$can(['SuperAdmin'])">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-user"></i>
            </template>
            {{ $t("Sidebar.ລາຍການ KYC") }}
          </vs-sidebar-item>
        </template>
        <div v-if="$can(['SuperAdmin'])">
          <vs-sidebar-item id="tab.user.kyc">
            <template #icon>
              <i class="fal fa-user"></i>
            </template>
            {{ $t("Sidebar.ລາຍການ KYC ລູກຄ້າ") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>

      <!-- Accounting Manager -->
      <vs-sidebar-group v-if="$can(isAccounting())">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-dolly-flatbed-alt"></i>
            </template>
            {{ $t("Sidebar.ລາຍການ Order ພັດສະດຸ") }}
          </vs-sidebar-item>
        </template>
        <div>
          <vs-sidebar-item v-if="$can(isAccounting())"
                           id="account.tab.all.shipments"
                           ref="account.tab.all.shipments"
                           :value="JSON.stringify({ query: { tab: tabQueryShipment } })"
          >
            <template #icon>
              <i class="fal fa-list-alt"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນ ລາຍການ orders") }}
          </vs-sidebar-item>
        </div>
        <div>
          <vs-sidebar-item
              id="account.tab.solve.shipments"
              ref="account.tab.solve.shipments"
              :value="JSON.stringify({ query: { tab: tabQueryShipment } })"
          >
            <template #icon>
              <i class="fal fa-engine-warning"></i>
            </template>
            {{ $t("Sidebar.ຄົ້ນຫາບິນ") }}
          </vs-sidebar-item>
        </div>
        <!--        report-->
        <div v-if="$can('accountingmanager')">
          <vs-sidebar-item
              id="account.tab.report.driver"
              ref="account.tab.report.driver"
              :value="JSON.stringify({ query: { tab: tabQueryShipment } })"
          >
            <template #icon>
              <i class="fal fa-truck"></i>
            </template>
            {{ $t("Sidebar.ລາຍການອໍເດີໂຊເພີລົດໃຫຍ່") }}
          </vs-sidebar-item>
        </div>

        <div v-if="$can('accountingmanager')">
          <vs-sidebar-item
              id="account.tab.report.distribution"
              ref="account.tab.report.distribution"
              :value="JSON.stringify({ query: { tab: tabQueryShipment } })"
          >
            <template #icon>
              <i class="fal fa-house"></i>
            </template>
            {{ $t("Sidebar.ລາຍການຍິງເຄື່ອງເຂົ້າ-ອອກ ສູນຄັດແຍກ") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>

      <vs-sidebar-group v-if="$can(isAccounting())">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-file-invoice-dollar"></i>
            </template>
            {{ $t("Sidebar.Invoices") }}
          </vs-sidebar-item>
        </template>
        <div>
          <vs-sidebar-item
              id="account.tab.list.shipment"
              ref="account.tab.list.shipment"
              :value="JSON.stringify({ query: { tab: tabQueryInvoice } })"
          >
            <template #icon>
              <i class="fal fa-list-alt"></i>
            </template>
            {{ $t("Sidebar.Orders ທີ່ຈະດຶງ Invoices") }}
          </vs-sidebar-item>
        </div>
        <div>
          <vs-sidebar-item
              id="account.tab.list.order"
              ref="account.tab.list.order"
              :value="JSON.stringify({ query: { tab: tabQueryInvoice } })"
          >
            <template #icon>
              <i class="fal fa-badge-dollar"></i>
            </template>
            {{ $t("Sidebar.ລາຍການ Invoices") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>
      <!-- End Accounting Manager -->

      <vs-sidebar-group v-if="$can(['accountingmanager', 'accounting-local'])">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-file-invoice"></i>
            </template>
            {{ $t("Sidebar.Invoices ສາຂາຈຸດແຈກຢາຍ") }}
          </vs-sidebar-item>
        </template>
        <div>
          <vs-sidebar-item
              id="tab.insert.invoice.local"
              ref="tab.insert.invoice.local"
              :value="JSON.stringify({ query: { tab: 'origin' } })"
          >
            <template #icon>
              <i class="fal fa-list-alt"></i>
            </template>
            {{ $t("Sidebar.Orders ທີ່ຈະດຶງ Invoices") }}
          </vs-sidebar-item>
        </div>
        <div>
          <vs-sidebar-item
              id="tab.invoice.local"
              ref="tab.invoice.local"
              :value="JSON.stringify({ query: { tab: 'origin' } })"
          >
            <template #icon>
              <i class="fal fa-badge-dollar"></i>
            </template>
            {{ $t("Sidebar.ລາຍການ Invoices") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>

      <vs-sidebar-group
          v-if="$can(['accountingmanager', 'accountingoriginfranchise'])"
      >
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-code-branch"></i>
            </template>
            {{ $t("Sidebar.Invoices ຍອດສຳເລັດຕົ້ນທາງແຟນຊາຍ") }}
          </vs-sidebar-item>
        </template>
        <div>
          <vs-sidebar-item
              id="tab.insert.invoice.franchise"
              ref="tab.insert.invoice.franchise"
              :value="JSON.stringify({ query: { tab: 'origin' } })"
          >
            <template #icon>
              <i class="fal fa-list-alt"></i>
            </template>
            {{ $t("Sidebar.Orders ທີ່ຈະດຶງ Invoices") }}
          </vs-sidebar-item>
        </div>
        <div>
          <vs-sidebar-item
              id="tab.invoice.franchise"
              ref="tab.invoice.franchise"
              :value="JSON.stringify({ query: { tab: 'origin' } })"
          >
            <template #icon>
              <i class="fal fa-badge-dollar"></i>
            </template>
            {{ $t("Sidebar.ລາຍການ Invoices") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>
      <!-- End Accounting Manager -->

      <!--      Chart Report-->
      <vs-sidebar-group
          v-if="
          $can([
            'SuperAdmin',
            'manager',
            'service',
            'marketing-viewer',
            'head-franchise',
          ])
        "
      >
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-chart-bar"></i>
            </template>
            {{ $t("Sidebar.ລາຍງານແບບ Graph") }}
          </vs-sidebar-item>
        </template>
        <div v-if="$can(['SuperAdmin', 'manager', 'service'])">
          <vs-sidebar-item id="graph.report">
            <template #icon>
              <i class="fal fa-chart-line"></i>
            </template>
            {{ $t("Sidebar.ລາຍງານແບບ Graph") }}
          </vs-sidebar-item>
        </div>
        <div v-if="$can(['marketing-viewer', 'SuperAdmin', 'head-franchise'])">
          <vs-sidebar-item id="shipment.qty.graph">
            <template #icon>
              <i class="fal fa-chart-bar"></i>
            </template>
            {{ $t("Sidebar.ລາຍງານຍອດ") }}
          </vs-sidebar-item>
        </div>

        <div></div>
      </vs-sidebar-group>

      <!--Accountant Manager-->
      <vs-sidebar-group v-if="$can(['accountingmanager'])">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-chart-line"></i>
            </template>
            {{ $t("Sidebar.ສະຫຼຸບບັນຊີ") }}
          </vs-sidebar-item>
        </template>
        <div v-if="$can(['accountingmanager'])">
          <vs-sidebar-item id="accountant.summary.branch">
            <template #icon>
              <i class="fal fa-home"></i>
            </template>
            {{ $t("Sidebar.ຄິດໄລ່ຍອດສາຂາ") }}
          </vs-sidebar-item>
        </div>
        <div v-if="$can(['accountingmanager'])">
          <vs-sidebar-item id="accountant.summary.distribution">
            <template #icon>
              <i class="fal fa-warehouse-alt"></i>
            </template>
            {{ $t("Sidebar.ຄິດໄລ່ຍອດສູນຄັດແຍກ") }}
          </vs-sidebar-item>
        </div>
        <div v-if="$can(['accountingmanager'])">
          <vs-sidebar-item id="accountant.summary.delivery.capital">
            <template #icon>
              <i class="fal fa-biking"></i>
            </template>
            {{ $t("Sidebar.ຄິດໄລ່ຍອດ delivery ນ/ຄຫຼວງ ລົດຈັກ") }}
          </vs-sidebar-item>
        </div>
        <div v-if="$can(['accountingmanager'])">
          <vs-sidebar-item id="accountant.summary.driver.capital">
            <template #icon>
              <i class="fal fa-truck"></i>
            </template>
            {{ $t("Sidebar.ຄິດໄລ່ຍອດ delivery ນ/ຄຫຼວງ ລົດໃຫຍ່") }}
          </vs-sidebar-item>
        </div>
        <div v-if="$can(['accountingmanager'])">
          <vs-sidebar-item id="accountant.summary.delivery.province">
            <template #icon>
              <i class="fal fa-bicycle"></i>
            </template>
            {{ $t("Sidebar.ຄິດໄລ່ຍອດ delivery ຕ່າງແຂວງລົດຈັກ") }}
          </vs-sidebar-item>
        </div>
        <div v-if="$can(['accountingmanager'])">
          <vs-sidebar-item id="accountant.summary.driver.province">
            <template #icon>
              <i class="fal fa-truck"></i>
            </template>
            {{ $t("Sidebar.ຄິດໄລ່ຍອດ delivery ຕ່າງແຂວງລົດໃຫຍ່") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>

      <!--Accountant cdc-->
      <vs-sidebar-group v-if="$can(['accountingcdc'])">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-chart-line"></i>
            </template>
            {{ $t("Sidebar.ສະຫຼຸບບັນຊີ") }}
          </vs-sidebar-item>
        </template>
        <div v-if="$can(['accountingcdc'])">
          <vs-sidebar-item id="cdc.summary.branch">
            <template #icon>
              <i class="fal fa-home"></i>
            </template>
            {{ $t("Sidebar.ຄິດໄລ່ຍອດສາຂາ") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>

      <!--      Marketing-->
      <vs-sidebar-group
          v-if="
          $can([
            'marketing',
            'accountingcod',
            'accountingmanager',
            'suburbMarketing',
          ])
        "
      >
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-money-check"></i>
            </template>
            {{ $t("Sidebar.ການຕະຫຼາດ") }}
          </vs-sidebar-item>
        </template>
        <div v-if="$can(['marketing', 'accountingmanager', 'suburbMarketing'])">
          <vs-sidebar-item id="marketing.regular.customer">
            <template #icon>
              <i class="fal fa-user-alt"></i>
            </template>
            {{ $t("Sidebar.ລາຍການລູກຄ້າເຊັນສັນຍາ") }}
          </vs-sidebar-item>
        </div>
        <div
            v-if="
            $can([
              'marketing',
              'accountingcod',
              'accountingmanager',
              'suburbMarketing',
            ])
          "
        >
          <vs-sidebar-item
              id="marketing.tab.shipment"
              ref="marketing.tab.shipment"
              :value="JSON.stringify({ query: { tab: 'all' } })"
          >
            <template #icon>
              <i class="fal fa-cash-register"></i>
            </template>
            {{ $t("Sidebar.ລາຍການພັດສະດຸລູກຄ້າເຊັນສັນຍາ") }}
          </vs-sidebar-item>
        </div>
        <div
            v-if="
            $can([
              'marketing',
              'accountingcod',
              'accountingmanager',
              'suburbMarketing',
            ])
          "
        >
          <vs-sidebar-item id="marketing.regular.summary">
            <template #icon>
              <i class="fal fa-chart-line"></i>
            </template>
            {{ $t("Sidebar.ສະຫຼຸບຍອດລູກຄ້າເຊັນສັນຍາ") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>

      <!--      Report Marketing-->
      <vs-sidebar-group v-if="$can(['SuperAdmin', 'accountingcod'])">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-poll-people"></i>
            </template>
            {{ $t("Sidebar.ລາຍງານຂໍ້ມູນລູກຄ້າ") }}
          </vs-sidebar-item>
        </template>
        <div v-if="$can(['SuperAdmin', 'accountingcod'])">
          <vs-sidebar-item id="admin.list.customer">
            <template #icon>
              <i class="fal fa-user-alt"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນລູກຄ້າ") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>

      <!--POINT-->
      <vs-sidebar-group v-if="$can(['SuperAdmin', 'manager', 'marketing'])">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-star"></i>
            </template>
            {{ $t("Sidebar.ລາຍງານຂໍ້ມູນຄະແນນລູກຄ້າ") }}
          </vs-sidebar-item>
        </template>
        <div v-if="$can(['SuperAdmin', 'manager', 'marketing'])">
          <vs-sidebar-item id="list.point">
            <template #icon>
              <i class="fal fa-award"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນຄະແນນ") }}
          </vs-sidebar-item>
        </div>
        <!--        <div v-if="$can(['SuperAdmin','manager', 'marketing'])">-->
        <!--          <vs-sidebar-item id="list.all.transaction">-->
        <!--            <template #icon>-->
        <!--              <i class='fal fa-exchange'></i>-->
        <!--            </template>-->
        <!--            ຂໍ້ມູນການເຄື່ອນໄຫວຄະແນນ-->
        <!--          </vs-sidebar-item>-->
        <!--        </div>-->
        <div v-if="$can(['SuperAdmin', 'marketing'])">
          <vs-sidebar-item id="print.all.point.transaction">
            <template #icon>
              <i class="fal fa-coins"></i>
            </template>
            {{ $t("Sidebar.Export ການເຄື່ອນໄຫວຄະແນນ") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>

      <!--INTERN MARKETING-->
      <vs-sidebar-group v-if="$can('headinternmarketing')">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-poll-people"></i>
            </template>
            {{ $t("Sidebar.ຂໍ້ມູນນັກສຶກສາຝຶກງານ") }}
          </vs-sidebar-item>
        </template>
        <div v-if="$can('headinternmarketing')">
          <vs-sidebar-item id="list.intern.marketing">
            <template #icon>
              <i class="fal fa-user-alt"></i>
            </template>
            {{ $t("Sidebar.ລາຍຊື່ນັກສຶກສາ") }}
          </vs-sidebar-item>
        </div>
        <div v-if="$can('headinternmarketing')">
          <vs-sidebar-item id="list.intern.register">
            <template #icon>
              <i class="fal fa-list"></i>
            </template>
            {{ $t("Sidebar.ລາຍການລົງທະບຽນ") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>

      <vs-sidebar-group v-if="$can(['manager', 'accountingcod'])">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-cash-register"></i>
            </template>
            {{ $t("Sidebar.ສະຫຼຸບຍອດ COD") }}
          </vs-sidebar-item>
        </template>
        <div v-if="$can(['accountingcod', 'manager'])">
          <vs-sidebar-item id="list.cod.customer.shipment">
            <template #icon>
              <i class="fal fa-list"></i>
            </template>
            {{ $t("Sidebar.ຍອດ COD ລູກຄ້າ") }}
          </vs-sidebar-item>
        </div>
        <div v-if="$can(['accountingcod'])">
          <vs-sidebar-item id="list.summary.cod">
            <template #icon>
              <i class="fal fa-house"></i>
            </template>
            {{ $t("Sidebar.ຍອດ COD ສາຂາ") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>

      <vs-sidebar-group
          v-if="
          $can([
            'SuperAdmin',
            'manager',
            'marketing',
            'marketinghelper',
            'shop-user',
            'shop-manager',
            'service',
            'accountingmanager',
            'accountingcod',
            'accountingcdc',
            'accountingoriginfranchise',
            'accountingoriginbranch',
          ])
        "
      >
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-house-signal"></i>
            </template>
            {{ $t("Sidebar.ຮ້ານຄ້າ") }}
          </vs-sidebar-item>
        </template>
        <div
            v-if="$can(['SuperAdmin', 'manager', 'marketing', 'marketinghelper'])"
        >
          <vs-sidebar-item id="list.shop">
            <template #icon>
              <i class="fal fa-home"></i>
            </template>
            {{ $t("Sidebar.ຮ້ານຄ້າ") }}
          </vs-sidebar-item>
        </div>
        <div
            v-if="
            $can([
              'SuperAdmin',
              'manager',
              'marketing',
              'marketinghelper',
              'shop-user',
              'shop-manager',
              'service',
              'accountingmanager',
              'accountingcod',
              'accountingcdc',
              'accountingoriginfranchise',
              'accountingoriginbranch',
            ])
          "
        >
          <vs-sidebar-item id="shop.payment">
            <template #icon>
              <i class="fal fa-chart-bar"></i>
            </template>
            {{ $t("Sidebar.ຍອດຮ້ານຄ້າ") }}
          </vs-sidebar-item>
        </div>
        <div
            v-if="$can(['SuperAdmin', 'manager', 'marketing', 'marketinghelper'])"
        >
          <vs-sidebar-item id="list.shop.user">
            <template #icon>
              <i class="fal fa-user"></i>
            </template>
            {{ $t("Sidebar.ຜູ້ໃຊ້ສຳຫຼັບຮ້ານຄ້າຮ່ວມລາຍການ") }}
          </vs-sidebar-item>
        </div>
        <div
            v-if="$can(['SuperAdmin', 'manager', 'marketing', 'marketinghelper'])"
        >
          <vs-sidebar-item id="list.shop.item.category">
            <template #icon>
              <i class="fal fa-list-alt"></i>
            </template>
            {{ $t("Sidebar.ປະເພດຂອງລາງວັນ") }}
          </vs-sidebar-item>
        </div>
        <div
            v-if="$can(['SuperAdmin', 'manager', 'marketing', 'marketinghelper'])"
        >
          <vs-sidebar-item id="list.shop.item">
            <template #icon>
              <i class="fal fa-gifts"></i>
            </template>
            {{ $t("Sidebar.ຂອງລາງວັນ") }}
          </vs-sidebar-item>
        </div>
        <div v-if="$can(['SuperAdmin', 'manager', 'marketing'])">
          <vs-sidebar-item id="list.exchange.item">
            <template #icon>
              <i class="fal fa-list"></i>
            </template>
            {{ $t("Sidebar.ປະຫວັດແລກຂອງລາງວັນ") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>

      <vs-sidebar-group v-if="$can('marketinghelper')">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-cash-register"></i>
            </template>
            {{ $t("Sidebar.ອິນວອຍຮ້ານຄ້າ") }}
          </vs-sidebar-item>
        </template>
        <div v-if="$can('marketinghelper')">
          <vs-sidebar-item id="list.insert.shop.invoice">
            <template #icon>
              <i class="fal fa-list"></i>
            </template>
            {{ $t("Sidebar.ລາຍການທີ່ຈະສ້າງອິນວອຍ") }}
          </vs-sidebar-item>
        </div>
        <div v-if="$can('marketinghelper')">
          <vs-sidebar-item id="list.shop.invoice">
            <template #icon>
              <i class="fal fa-money-bill"></i>
            </template>
            {{ $t("Sidebar.ອິນວອຍ") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>

      <vs-sidebar-group v-if="$can('marketinghelper')">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-history"></i>
            </template>
            {{ $t("Sidebar.ປະຫວັດການຂາຍເລກ") }}
          </vs-sidebar-item>
        </template>
        <div v-if="$can('marketinghelper')">
          <vs-sidebar-item id="list.lottery.history">
            <template #icon>
              <i class="fal fa-list"></i>
            </template>
            {{ $t("Sidebar.ລາຍການຂາຍເລກ") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>

      <vs-sidebar-group v-if="$can(['SuperAdmin', 'manager', 'marketing'])">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-box-heart"></i>
            </template>
            {{ $t("Sidebar.ກ່ອງລຸ້ນໂຊກ") }}
          </vs-sidebar-item>
        </template>
        <div v-if="$can(['SuperAdmin', 'manager', 'marketing'])">
          <vs-sidebar-item id="list.prize">
            <template #icon>
              <i class="fal fa-box"></i>
            </template>
            {{ $t("Sidebar.ຂອງລາງວັນ") }}
          </vs-sidebar-item>
        </div>
        <div v-if="$can(['SuperAdmin', 'manager', 'marketing'])">
          <vs-sidebar-item id="list.prize.result">
            <template #icon>
              <i class="fal fa-history"></i>
            </template>
            {{ $t("Sidebar.ປະຫວັດການເປີດກ່ອງ") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>

      <vs-sidebar-group v-if="$can(['SuperAdmin', 'service'])">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-money-bill"></i>
            </template>
            {{ $t("Sidebar.Export ບິນ") }}
          </vs-sidebar-item>
        </template>
        <div v-if="$can(['SuperAdmin','service'])">
          <vs-sidebar-item id="print.all.shipment">
            <template #icon>
              <i class="fal fa-print"></i>
            </template>
            {{ $t("Sidebar.ລາຍການບິນ") }}
          </vs-sidebar-item>
        </div>
        <div v-if="$can(['SuperAdmin'])">
          <vs-sidebar-item id="print.shipment.from.capital">
            <template #icon>
              <i class="fal fa-shipping-fast"></i>
            </template>
            {{ $t("Sidebar.ລາຍການບິນນຄຫຼວງ-ຕ່າງແຂວງ") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>
      <vs-sidebar-group v-if="$can(['SuperAdmin', 'TaxViewer'])">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-list-alt"></i>
            </template>
            {{ $t("Sidebar.ລາຍການບິນແຍກຕາມສາຂາ") }}
          </vs-sidebar-item>
        </template>
        <div v-if="$can(['SuperAdmin', 'TaxViewer'])">
          <vs-sidebar-item id="shipment.branch.quantities">
            <template #icon>
              <i class="fal fa-list"></i>
            </template>
            {{ $t("Sidebar.ລາຍການບິນສຳເລັດ") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>
      <vs-sidebar-group v-if="$can(['halcenterlive'])">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-tv-retro"></i>
            </template>
            {{ $t("Sidebar.HAL CENTER LIVE") }}
          </vs-sidebar-item>
        </template>
        <div v-if="$can(['halcenterlive'])">
          <vs-sidebar-item id="hal.live.coupon">
            <template #icon>
              <i class="fal fa-credit-card-blank"></i>
            </template>
            {{ $t("Sidebar.ຄູປອງ") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>

      <vs-sidebar-group v-if="$can(['SuperAdmin', 'news'])">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-award"></i>
            </template>
            {{ $t("Sidebar.ຈັດການໜ້າ Event") }}
          </vs-sidebar-item>
        </template>
        <div v-if="$can(['SuperAdmin', 'news'])">
          <vs-sidebar-item id="event.promotion">
            <template #icon>
              <i class="fal fa-list"></i>
            </template>
            {{ $t("Sidebar.ລາຍການ") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>
      <!--      <vs-sidebar-group v-if="$can('accountingcdc')">-->
      <!--        <template #header>-->
      <!--          <vs-sidebar-item arrow>-->
      <!--            <template #icon>-->
      <!--              <i class='fal fa-not-equal'></i>-->
      <!--            </template>-->
      <!--            ເຄື່ອງຕີກັບ CDC-->
      <!--          </vs-sidebar-item>-->
      <!--        </template>-->
      <!--        <div>-->
      <!--          <vs-sidebar-item id="list.reject.cdc.shipment">-->
      <!--            <template #icon>-->
      <!--              <i class='fal fa-list'></i>-->
      <!--            </template>-->
      <!--            ລາຍການເຄື່ອງຕີກັບ-->
      <!--          </vs-sidebar-item>-->
      <!--        </div>-->
      <!--      </vs-sidebar-group>-->

      <vs-sidebar-group v-if="$can(['SuperAdmin', 'hr', 'service', 'manager', 'marketing'])">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fal fa-comment-alt-exclamation"></i>
            </template>
            {{ $t("Sidebar.ຄຳຕິຊົມ") }}
          </vs-sidebar-item>
        </template>
        <div v-if="$can(['SuperAdmin', 'hr', 'service', 'manager', 'marketing'])">
          <vs-sidebar-item id="feedback.index">
            <template #icon>
              <i class="fal fa-comment-alt-exclamation"></i>
            </template>
            {{ $t("Sidebar.ຄຳຕິຊົມສາຂາ") }}
          </vs-sidebar-item>
          <vs-sidebar-item id="feedback-user.index">
            <template #icon>
              <i class="fal fa-comment-alt-exclamation"></i>
            </template>
            {{ $t("Sidebar.ຄຳຕິຊົມພະນັກງານ") }}
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>
      <vs-sidebar-group
          v-if="
          $can([
            'branchuser',
            'branchmanager',
            'franchise-manager',
            'distributioncenteruser',
            'distributioncentermanager',
            'service',
          ])
        "
      >
        <template #header>
          <vs-sidebar-item arrow v-if="!$can('organization-manager')">
            <template #icon>
              <i class="far fa-dolly"></i>
            </template>
            {{ $t("Sidebar.ຄັງພັດສະດຸ") }}
          </vs-sidebar-item>
        </template>
        <div>
          <div v-if="$can(['branchuser', 'service'])">
            <vs-sidebar-item id="parcelBranch.index">
              <template #icon>
                <i class="fal fa-scanner"></i>
              </template>
              {{ $t("Sidebar.ຄັງພັດສະດຸສາຂາ") }}
            </vs-sidebar-item>
          </div>
          <div
              v-if="
              $can([
                'distributioncenteruser',
                'distributioncentermanager',
                'service',
              ])
            "
          >
            <vs-sidebar-item id="parcelDistributionCenter.index">
              <template #icon>
                <i class="far fa-pallet-alt"></i>
              </template>
              {{ $t("Sidebar.ຄັງພັດສະດຸສູນຄັດແຍກ") }}
            </vs-sidebar-item>
          </div>
        </div>
      </vs-sidebar-group>
      <div
          v-if="$can(['SuperAdmin', 'manager', 'marketing', 'marketinghelper'])"
      >
        <vs-sidebar-item id="referralRegister.index">
          <template #icon>
            <i class="fal fa-user-chart"></i>
          </template>
          {{ $t("Sidebar.ລາຍການແນະນຳລູກຄ້າລົງທະບຽນໃໝ່") }}
        </vs-sidebar-item>
      </div>
      <div v-if="$can(['SuperAdmin', 'marketing'])">
        <vs-sidebar-item id="customer.shipment.total">
          <template #icon>
            <i class="fal fa-money-bill"></i>
          </template>
          {{ $t("Sidebar.ລາຍການຍອດຝາກລູກຄ້າ") }}
        </vs-sidebar-item>
      </div>

      <div v-if="$can(['data-viewer', 'SuperAdmin'])">
        <vs-sidebar-item id="weekly.data.report">
          <template #icon>
            <i class="fal fa-file-spreadsheet"></i>
          </template>
          {{ $t("Sidebar.data_report") }}
        </vs-sidebar-item>
      </div>

      <div v-if="$can(['SuperAdmin', 'injured-parcel-user'])">
        <vs-sidebar-item id="injuredParcel.index">
          <template #icon>
            <i class="far fa-clipboard-list"></i>
          </template>
          {{ $t("Sidebar.ລາຍການເກັບກຳເຄື່ອງເສຍຫາຍ") }}
        </vs-sidebar-item>
      </div>

      <div v-if="$can(['accountingmanager'])">
        <vs-sidebar-item id="vendor.bill.index">
          <template #icon>
            <i class="fal fa-ballot-check"></i>
          </template>
          {{ $t("Sidebar.ບິນຈາກຮ້ານຄ້າ") }}
        </vs-sidebar-item>
      </div>

      <div v-if="$can(['SuperAdmin', 'settle-shipment-status-manager'])">
        <vs-sidebar-item id="shipment.settlement.index">
          <template #icon>
            <i class="fal fa-money-check"></i>
          </template>
          {{ $t("Sidebar.ບິນຮຽກເກັບເງິນ") }}
        </vs-sidebar-item>
      </div>

      <div v-if="$can(['SuperAdmin', 'cleared-shipment-status-manager'])">
        <vs-sidebar-item id="shipment.cleared.index">
          <template #icon>
            <i class="far fa-dolly"></i>
          </template>
          {{ $t("Sidebar.ສະສາງພັດສະດຸ") }}
        </vs-sidebar-item>
      </div>
      <div v-if="$can(['SuperAdmin', 'settle-shipment-status-manager'])">
        <vs-sidebar-item id="shipment.blocked.sign.index">
          <template #icon>
            <i class="far fa-shield-alt"></i>
          </template>
          {{ $t("Sidebar.ລັອກເຊັນຮັບພັດສະດຸ") }}
        </vs-sidebar-item>
      </div>

      <div v-if="$can(['accountingcdc'])">
        <vs-sidebar-item id="franchise.owner.index">
          <template #icon>
            <i class="fal fa-store-alt"></i>
          </template>
          ລາຍການເຈົ້າຂອງເເຟນຊາຍ
        </vs-sidebar-item>
      </div>

      <div
          v-if="($can('accountingcod') || $can('branchmanager') || $can('franchise-manager')) && !$can('organization-manager') && $i18n.locale !== 'CN'">
        <vs-sidebar-item id="cod.branches.balance.index">
          <template #icon>
            <i class="fal fa-money-bill"></i>
          </template>
          ລາຍລະອຽດສ່ວນແບ່ງ COD
        </vs-sidebar-item>
      </div>

    </vs-sidebar>
  </div>
</template>
<script>
export default {
  data: () => ({
    active: "",
    changeByRoute: false,
    tabQueryInvoice: "",
    tabQueryShipment: "",
  }),
  watch: {
    active() {
      let optionals = {};
      const ref = this.$refs[this.active];
      const {params, query} = this.$route;
      if (ref) {
        optionals = JSON.parse(ref.value);
      }
      if (this.changeByRoute) {
        optionals.params = {...optionals.params, ...params};
        optionals.query = {...optionals.query, ...query};
        this.changeByRoute = false;
      }
      this.$router.push({name: this.active, ...optionals}).catch(() => {
      });
    },
    "$route.name": function (name) {
      if (name !== this.active) {
        this.changeByRoute = true;
        this.activeSidebarMenuItem(name);
      }
    },
  },
  methods: {
    activeSidebarMenuItem(name) {
      if (name) {
        this.active = name;
        const ref = this.$refs.sidebar;
        if (ref) {
          const children = ref.$children;
          children.map((group) => {
            const activeChildGroup = group.$children.filter(
                (menu) => menu.id === name
            );
            if (activeChildGroup.length) {
              group.openState = true;
            }
          });
        }
      }
    },
    isAccountingAndAdmin() {
      return [
        "SuperAdmin",
        "accountingmanager",
        "accountingoriginbranch",
        "accountingoriginfranchise",
        "accountingcdc",
        "accountingcod",
      ];
    },
    isAccounting() {
      return [
        "accountingmanager",
        "accountingoriginbranch",
        "accountingoriginfranchise",
        "accountingcdc",
        "accountingcod",
      ];
    },
    isBranchCLogisticsAccounting() {
      return [
        "c-logistics-branch-accounting",
      ];
    },
  },
  mounted() {
    const {name} = this.$route;
    this.changeByRoute = true;
    this.activeSidebarMenuItem(name);

    //forInvoiceTab
    if (
        this.$can([
          "accountingmanager",
          "accountingoriginbranch",
          "accountingoriginfranchise",
        ])
    ) {
      this.tabQueryInvoice = "origin_freight";
      this.tabQueryInvoiceLocal = "origin";
    } else if (this.$can("accountingcdc")) {
      this.tabQueryInvoice = "destination_freight";
    } else {
      this.tabQueryInvoice = "parcel_price";
    }

    //forShipmentTab
    if (this.$can(["accountingoriginbranch", "accountingoriginfranchise"])) {
      this.tabQueryShipment = "origin_freight_fees";
    } else if (this.$can("accountingcdc")) {
      this.tabQueryShipment = "destination_freight_fees";
    } else if (this.$can("accountingcod")) {
      this.tabQueryShipment = "origin_freight_fees_cod";
    } else {
      this.tabQueryShipment = "all";
    }
  },
};
</script>

<style lang="scss">
.vs-sidebar__group__content {
  margin-left: 5px;
}
</style>
