import Vue from 'vue';
import VueRouter from 'vue-router';
import FormSignin from '../views/SuperAdmin/Sign-In';
import RoleUsers from '../views/SuperAdmin/Roles/List-Roles';
import PermissionUsers from '../views/SuperAdmin/Permissions/List-Permission';
import PermissionRoles from '../views/SuperAdmin/Permission-Roles/List-Permission-Roles';
import TabListPackages from '../views/SuperAdmin/Packages/Tab-List';
import AddPackages from '../views/SuperAdmin/Packages/Add&Edit/Add';
import EditPackages from '../views/SuperAdmin/Packages/Add&Edit/Edit';
import PackageSizes from '../views/SuperAdmin/Package-Size/List-Package-Size';
import Villages from '../views/SuperAdmin/Addresses/Villages/List-Villages';

import branches from '../views/SuperAdmin/Branches/List-Branches';
import AddBranches from '../views/SuperAdmin/Branches/Add&Edit/Add';
import EditBranches from '../views/SuperAdmin/Branches/Add&Edit/Edit';
import branchUsers from '../views/SuperAdmin/Branches/Users/List-Users';
import TabPromotions from '../views/SuperAdmin/Promotions/Tab-Promotion';
import Graph from '@/views/SuperAdmin/GraphReport/graph';
// Add-Promotions
import AddPromotions from '../views/SuperAdmin/Promotions/Add&Edit/Add';
import EditPromotions from '../views/SuperAdmin/Promotions/Add&Edit/Edit';

// Coupon
import ListCoupons from '../views/SuperAdmin/Coupons/Tab-Coupon';
// List Banners
import ListBanners from '../views/SuperAdmin/Banners/List-Banners';
// List Contact Us
import ListContactUs from '../views/SuperAdmin/ContactUs/List-ContactUs';
// List Parcel-Categories
import ListParcelCategories from '../views/SuperAdmin/Parcel-Categories/List-Categories';
// List Parcel-Statuses
import ListShipmentStatuses from '../views/SuperAdmin/Shipment-Statuses/List-Shipment-Statuses';
// List News
import ListNews from '../views/SuperAdmin/News/List-News';
// Add News
import AddNews from '../views/SuperAdmin/News/Add&Edit/Add';
// Edit News
import EditNews from '../views/SuperAdmin/News/Add&Edit/Edit';

// List-Distribution-Centers
import ListDistributionCenter from '../views/SuperAdmin/Distribution-Center/List-Distribution-Center';
import ListDistributionCenterUsers from '../views/SuperAdmin/Distribution-Center/Users/List-Users';
import ListDistributionCenterLinks from '../views/SuperAdmin/Distribution-Center/Links/List-Distribution-Center-Link';
import BranchLinkIndex from '@/views/SuperAdmin/Branches/Link/BranchLinkIndex';

// List-Delivery && Users
import ListDeliveries from '../views/SuperAdmin/Deliveries/List-Deliveries';
import AddDeliveries from '../views/SuperAdmin/Deliveries/Add&Edit/Add';
import EditDeliveries from '../views/SuperAdmin/Deliveries/Add&Edit/Edit';
import ListDeliveryUsers from '../views/SuperAdmin/Deliveries/Users/List-Users';

// List Call-Center && Users
import ListCallCenters from '../views/SuperAdmin/Call-Centers/List-Call-Centers';
import ListCallCenterUsers from '../views/SuperAdmin/Call-Centers/Users/List-Users';

// List Vehicle-Department && Users
import ListVehicleDepartments from '../views/SuperAdmin/Vehicle-Departments/List-Vehicle-Department';
import ListUserVehicleDepartments from '../views/SuperAdmin/Vehicle-Departments/Users/List-User-VehicleDepartment';

import Middlewares from '../Middlewares/Index';

//Customer
import TapGeneralCustomer from '../views/SuperAdmin/Customers/GeneralCustomer/tap-general-customer'
import TabVipCustomer from '@/views/SuperAdmin/Customers/VIPCustomer/tap-vip-customer'
import TabCompanyCustomer from '@/views/SuperAdmin/Customers/CompanyCustomer/tap-company-customer'
import ListCelebrate from '@/views/SuperAdmin/Customers/CelebrateCustomer/list-celebrate'

//Celebrate
import HomeWelcome from "@views/SuperAdmin/Customers/CelebrateCustomer/RegisterCustomer/HomeWelcome";
import ConfirmRegister from "@views/SuperAdmin/Customers/CelebrateCustomer/RegisterCustomer/ConfirmRegister";
import SuccessRegister from "@views/SuperAdmin/Customers/CelebrateCustomer/RegisterCustomer/SuccessRegister";

// Delivery
import TabDeliveryOrderRequests from '../views/Delivery/Tab-Orders';
import ReporDeliverytListOrders from '../views/Delivery/Reports/List-Report';

// Shipments
import TabListAllShipments from '../views/SuperAdmin/Shipments/Tab-Shipment';
import TabListAllShipmentPreOrders from '../views/SuperAdmin/Shipments/Pre-Orders/Tab-PreOrder';
import ListShipmentDetail from '../views/SuperAdmin/Shipments/List-Shipment-Detail';

//Accounting shipment
import TabListAllShipmentsAccounting from '@/views/Accounting/Shipments/Tab-Shipment';
import ListShipmentDetailAccounting from '@/views/Accounting/Shipments/List-Shipment-Detail';
//Report
//DistributionCenter
import tabReportDistribution from '@/views/SuperAdmin/Report/DistributionCenter/Tab-Distribution-Center';
import tabReportDistributionAccount from '@/views/Accounting/DistributionCenter/Tab-Distribution-Center';
//driver
import tabReportDriver from '@/views/SuperAdmin/Report/Driver/Tab-Driver';
import tabReportDriverAccount from '@/views/Accounting/Driver/Tab-Driver';
// Accounting
import ListAccountingUser from '../views/SuperAdmin/Accountings/List-Users';

import branch_accounting from './branch_accounting';

///Employee
import employee from '@/views/SuperAdmin/Employees/List-Employees'
///.
import chat from '@/views/Chat/chat'

//Marketing
import marketingTabShipment from '@/views/Marketing/Tab-Shipment'
import marketingRegularCustomer from '@/views/Marketing/RegularCustomer/list-regular'
import MarketingListShipmentDetail from '@/views/Marketing/List-Shipment-Detail'

//AdminReportCustomer
import adminListCustomer from '@/views/SuperAdmin/Report/CustomerReport/list-customers'

/** Import Report Order Request Of Rider  */
import RiderReportOrderRequests from '@/views/SuperAdmin/Report/OrderRequests/List-Orders'

/*** Intern Marketing ***/
import listInternMarketing from '@/views/SuperAdmin/InternMarketing/List-Intern'
import listInternRegister from '@/views/SuperAdmin/InternMarketing/List-Intern-register'

/*** Point SYSTEM ***/
import listPoint from '@/views/SuperAdmin/Point/list-point'
import listPointDetail from '@/views/SuperAdmin/Point/list-point-detail'
import listAllTransaction from '@/views/SuperAdmin/Point/list-all-transaction'

///****Accountant Summary****///
import accountantSummaryBranch from '@/views/SuperAdmin/AccountantManager/accountSummaryBranch'
import accountantSummaryDistribution from '@/views/SuperAdmin/AccountantManager/accountantSummaryDistribution'
import accountantSummaryDeliveryCapital from '@views/SuperAdmin/AccountantManager/accountantSummaryDeliveryCapital'
import accountantSummaryDriverCapital from '@views/SuperAdmin/AccountantManager/accountantSummaryDriverCapital'
import accountantSummaryDeliveryProvince from '@views/SuperAdmin/AccountantManager/accountantSummaryDeliveryProvince'
import accountantSummaryDriverProvince from "@views/SuperAdmin/AccountantManager/accountantSummaryDriverProvince";
///****cdc Summary****///
import cdcSummaryBranch from '@/views/SuperAdmin/AccountantCdc/cdcSummaryBranch'

//**summary regular customer///
import summaryRegularCustomer from '@/views/Marketing/Summary/summaryShipmentRegular'

//**Exchange Item**//
import listShop from '@/views/ExchangeItem/Shop/list-shop'
import listShopItem from '@/views/ExchangeItem/ShopItem/list-shop-item'
import listShopItemCategory from '@/views/ExchangeItem/ShopItemCategory/list-shop-item-category'
import listExchangeItem from '@/views/ExchangeItem/list-all-exchange'

//*listCodCustomerShipment*//
import listCodCustomerShipment from '@/views/SuperAdmin/AccountantCod/list-cod-customer-shipment';


/**
 * feed back
 */
import FeedBack from "@views/SuperAdmin/FeedBack/Index";
import viewFeedBackDetail from "@views/SuperAdmin/FeedBack/viewFeedBackDetail";


/**
 * Shipment Issue Stock
 */
import ShipmentIssueStockPage from "@views/SuperAdmin/ShipmentIssueStock/ShipmentIssueStockPage";


import printAllShipment from '@/views/SuperAdmin/ExportAllShipmentByJob/exportAllShipmentByJob'

import listSummaryCodBranch from "@views/SuperAdmin/AccountantCod/listSummaryCodBranch";

import halLiveCoupon from "@views/SuperAdmin/HalLive/halLiveCoupon";

import listSignCdc from "@views/Branch/SignCdc/listSignCdc";
import listSignCod from "@views/Branch/SignCod/listSignCod";

import listShopUser from "@views/ExchangeItem/ShopUser/list-shop-user";

import exportShipmentFromCapital from "@views/SuperAdmin/ExportAllShipmentByJob/exportShipmentFromCapital";
import listPrize from "@views/Prize/list-prize";
import listPrizeResult from "@views/Prize/list-prize-result";

import shopPayment from "@views/ExchangeItem/Shop/shop-payment";
import eventPromotion from "@views/EventPage/eventPromotion";
import exportPointTransactionByJob from "@views/SuperAdmin/Point/exportPointTransactionByJob";
import branchPayment from "@views/ExchangeItem/Shop/branch-payment";
import listDashboard from "@views/SuperAdmin/Dashboard/List-Dashboard";

import shipmentQtyGraph from "@views/SuperAdmin/GraphReport/shipmentQtyGraph";
import listForInsertInvoice from "@views/ShopInvoicePoint/listForInsertInvoice";
import listShopInvoice from "@views/ShopInvoicePoint/listShopInvoice";
import listShopInvoiceDetail from "@views/ShopInvoicePoint/listShopInvoiceDetail";
import listSummaryBranch from "@views/Branch/summary/listSummaryBranch";
import listLotteryHistory from "@views/lottery/listLotteryHistory";
import tabAutoApproved from "@views/SuperAdmin/AccountantCod/AutoApprovedCod/TabAutoApproved";
import listAutoApproved from "@views/SuperAdmin/AccountantCod/AutoApprovedCod/ListAutoApproved";
import listAccountBank from "@views/SuperAdmin/AccountantCod/ListAccountBank";
import CustomerShipmentTotal from "@views/CustomerShipmentTotal/customer-shipment-total";

/**
 * Post Box
 */

import PostBoxIndex from "@views/PostBox/PostBoxIndex.vue";
import AddPostBox from "@views/PostBox/AddPostBox.vue";
import EditPostBox from "@views/PostBox/EditPostBox.vue";

/**
 * province manager
 */

import TabShipmentProvinceManager from "@views/ProvinceManager/Shipments/Tab-Shipment.vue"
import tabUserKYC from "@views/SuperAdmin/UserKYC/TabUserKYC.vue";
import WeeklyDataReport from "@views/WeeklyDataReport/weekly-data-report.vue";

Vue.use(VueRouter)


const routes = [
    {
        path: '/list-role-users',
        name: 'list.role.users',
        component: RoleUsers,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list-permission-users',
        name: 'list.permission.users',
        component: PermissionUsers,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list-permission-roles/:id',
        name: 'list.permission.roles',
        component: PermissionRoles,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list-package-size',
        name: 'list.package.size',
        component: PackageSizes,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/tab-list-packages/:type',
        name: 'tab.list.packages',
        component: TabListPackages,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/add-packages',
        name: 'add.packages',
        component: AddPackages,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/edit-packages/:id',
        name: 'edit.packages',
        component: EditPackages,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list-news',
        name: 'list.news',
        component: ListNews,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/add-news',
        name: 'add.news',
        component: AddNews,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/edit-news/:id',
        name: 'edit.news',
        component: EditNews,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/tab-list-promotion',
        name: 'tab.list.promotion',
        component: TabPromotions,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/add-promotion',
        name: 'add.promotion',
        component: AddPromotions,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/edit-promotion/:id',
        name: 'edit.promotion',
        component: EditPromotions,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/tab-list-coupon',
        name: 'tab.list.coupon',
        component: ListCoupons,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list-villages',
        name: 'list.villages',
        component: Villages,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list-banners',
        name: 'list.banners',
        component: ListBanners,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list-contact-us',
        name: 'list.contact-us',
        component: ListContactUs,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list-parcel-categories',
        name: 'list.parcel-categories',
        component: ListParcelCategories,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list-shipment-status',
        name: 'list.shipment.status',
        component: ListShipmentStatuses,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list-branches/:type',
        name: 'list.branches',
        component: branches,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/add-branches',
        name: 'add.branches',
        component: AddBranches,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/edit-branches/:id',
        name: 'edit.branches',
        component: EditBranches,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/branch-links/:id/:type',
        name: 'branch-links',
        component: BranchLinkIndex,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list-branch-users',
        name: 'list.branch.users',
        component: branchUsers,
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    // List-Distribution-Center
    {
        path: '/list-distribution-centers',
        name: 'list.distribution.center',
        component: ListDistributionCenter,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list-distribution-centers-users',
        name: 'list.distribution.center.users',
        component: ListDistributionCenterUsers,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list-distribution-centers-links/:id',
        name: 'list.distribution.center.links',
        component: ListDistributionCenterLinks,
        meta: {
            middleware: [Middlewares.auth],
        }
    }, // End

    {
        path: '/list-deliveries',
        name: 'list.deliveries',
        component: ListDeliveries,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/add-deliveries',
        name: 'add.deliveries',
        component: AddDeliveries,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/edit-deliveries/:id',
        name: 'edit.deliveries',
        component: EditDeliveries,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list-delivery-users',
        name: 'list.delivery.user',
        component: ListDeliveryUsers,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list-call-centers',
        name: 'list.call.centers',
        component: ListCallCenters,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list-vehicle-department',
        name: 'list.vehicle.department',
        component: ListVehicleDepartments,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list-user-vehicle-departments',
        name: 'list.user.vehicle.departments',
        component: ListUserVehicleDepartments,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list-call-center-users',
        name: 'list.call.center.users',
        component: ListCallCenterUsers,
        meta: {
            middleware: [Middlewares.auth],
        },

        beforeEnter: (to, from, next) => {
            checkRoles(to, from, next);
        }
    },

    {
        path: '/list-post-boxes',
        name: 'list.post_boxes',
        component: PostBoxIndex,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list-post-boxes/add',
        name: 'post_box.add',
        component: AddPostBox,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list-post-boxes/edit/:id',
        name: 'post_box.edit',
        component: EditPostBox,
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    // Accounting
    {
        path: '/list-accounting-user',
        name: 'list.accounting.user',
        component: ListAccountingUser,
        meta: {
            middleware: [Middlewares.auth],
        }
    },


    {
        path: '/',
        name: 'sign-in',
        component: FormSignin,
        meta: {
            middleware: [Middlewares.guest],
            hiddens: true,
        }
    },

    {
        path: '/dashboard',
        name: 'list.dashboard',
        component: listDashboard,
        meta: {
            middleware: [Middlewares.auth],
        },
        beforeEnter: (to, from, next) => {
            checkRoles(to, from, next);
        }
    },

    {
        path: '/not-found',
        name: 'not.found',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Not-Found.vue'),
        meta: {
            //middleware: [Middlewares.auth],
            hiddens: true,
        }
    },

    //Customer
    //General Customer
    {
        path: '/tab-general-customer',
        name: 'tab.general.customer',
        component: TapGeneralCustomer,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    //VIP Customer
    {
        path: '/tab-vip-customer',
        name: 'tab.vip.customer',
        component: TabVipCustomer,
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    {
        path: '/tab-company-customer',
        name: 'tab.company.customer',
        component: TabCompanyCustomer,
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    //Celebrate
    {
        path: '/list-celebrate',
        name: 'list.celebrate',
        component: ListCelebrate,
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    {
        path: '/hal-welcome',
        name: 'hal.welcome',
        component: HomeWelcome,
        meta: {
            middleware: [Middlewares.guest],
            hiddens: true,
        }
    },

    {
        path: '/hal-register',
        name: 'hal.register',
        component: ConfirmRegister,
        meta: {
            middleware: [Middlewares.guest],
            hiddens: true,
        }
    },

    {
        path: '/hal-success',
        name: 'hal.success',
        component: SuccessRegister,
        meta: {
            middleware: [Middlewares.guest],
            hiddens: true,
        }
    },

    // Route of Delivery
    {
        path: '/delivery-tab-orders',
        name: 'delivery.tab.order.request',
        component: TabDeliveryOrderRequests,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/delivery-list-reports',
        name: 'delivery.list.reports',
        component: ReporDeliverytListOrders,
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    // Admin List Shipments
    {
        path: '/tab-list-all-shipments',
        name: 'tab.all.shipments',
        component: TabListAllShipments,
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    //Accounting list shipment
    {
        path: '/account/tab-list-all-shipments',
        name: 'account.tab.all.shipments',
        component: TabListAllShipmentsAccounting,
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    {
        path: '/account/list-shipment-detail/:id',
        name: 'account.list.shipment.detail',
        component: ListShipmentDetailAccounting,
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    // Admin List Shipments Pre-Order
    {
        path: '/tab-list-all-shipment-pre-orders',
        name: 'tab.all.shipment.pre.order',
        component: TabListAllShipmentPreOrders,
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    {
        path: '/list-shipment-detail/:id',
        name: 'list.shipment.detail',
        component: ListShipmentDetail,
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    {
        path: '/shipment-issue-stock',
        name: 'shipment-issue-stock',
        component: ShipmentIssueStockPage,
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    //Report
    //DistributionCenter
    {
        path: '/tab-report-distribution',
        name: 'tab.report.distribution',
        component: tabReportDistribution,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/account/tab-report-distribution',
        name: 'account.tab.report.distribution',
        component: tabReportDistributionAccount,
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    {
        path: '/tab-report-driver',
        name: 'tab.report.driver',
        component: tabReportDriver,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/account/tab-report-driver',
        name: 'account.tab.report.driver',
        component: tabReportDriverAccount,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/graph/report',
        name: 'graph.report',
        component: Graph,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list/employee',
        name: 'list.employee',
        component: employee,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/marketing/tab/shipment',
        name: 'marketing.tab.shipment',
        component: marketingTabShipment,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/marketing/regular/customer',
        name: 'marketing.regular.customer',
        component: marketingRegularCustomer,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/marketing/list-shipment-detail/:id',
        name: 'marketing.list.shipment.detail',
        component: MarketingListShipmentDetail,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/admin/list-customer',
        name: 'admin.list.customer',
        component: adminListCustomer,
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    /********* Report Order Request Of Rider ************/

    {
        path: '/report/order/requests',
        name: 'list.report.order.request',
        component: RiderReportOrderRequests,
        meta: {
            middleware: [Middlewares.auth],
        }
    },


    /*
    @FOR INTERN MARKETING
     */
    {
        path: '/list/intern/marketing',
        name: 'list.intern.marketing',
        component: listInternMarketing,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list/intern/register',
        name: 'list.intern.register',
        component: listInternRegister,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list/point',
        name: 'list.point',
        component: listPoint,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list/point/detail/:id',
        name: 'list.point.detail',
        component: listPointDetail,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list/all/transaction/',
        name: 'list.all.transaction',
        component: listAllTransaction,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    /*
    @END FOR INTERN MARKETING
     */

    /*
    @summary for accountant manager
     */
    {
        path: '/accountant/summary/branch/',
        name: 'accountant.summary.branch',
        component: accountantSummaryBranch,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/accountant/summary/distribution/',
        name: 'accountant.summary.distribution',
        component: accountantSummaryDistribution,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/accountant/summary/delivery-capital',
        name: 'accountant.summary.delivery.capital',
        component: accountantSummaryDeliveryCapital,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/accountant/summary/driver-capital',
        name: 'accountant.summary.driver.capital',
        component: accountantSummaryDriverCapital,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/accountant/summary/delivery-province',
        name: 'accountant.summary.delivery.province',
        component: accountantSummaryDeliveryProvince,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/accountant/summary/driver-capital',
        name: 'accountant.summary.driver.province',
        component: accountantSummaryDriverProvince,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/cdc/summary/branch',
        name: 'cdc.summary.branch',
        component: cdcSummaryBranch,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/marketing/regular/summary',
        name: 'marketing.regular.summary',
        component: summaryRegularCustomer,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    /*
    * Exchange item
     */
    {
        path: '/list/shop',
        name: 'list.shop',
        component: listShop,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list/shop/item',
        name: 'list.shop.item',
        component: listShopItem,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list/exchange/item',
        name: 'list.exchange.item',
        component: listExchangeItem,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list/shop/item/category',
        name: 'list.shop.item.category',
        component: listShopItemCategory,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list/cod/customer/shipment',
        name: 'list.cod.customer.shipment',
        component: listCodCustomerShipment,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/print/all/shipment',
        name: 'print.all.shipment',
        component: printAllShipment,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/print/shipment/from/capital',
        name: 'print.shipment.from.capital',
        component: exportShipmentFromCapital,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list/summary/cod',
        name: 'list.summary.cod',
        component: listSummaryCodBranch,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/hal/live/coupon',
        name: 'hal.live.coupon',
        component: halLiveCoupon,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list/sign/cdc',
        name: 'list.sign.cdc',
        component: listSignCdc,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {

        path: '/list/sign/cod',
        name: 'list.sign.cod',
        component: listSignCod,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {

        path: '/list/shop/user',
        name: 'list.shop.user',
        component: listShopUser,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {

        path: '/list/prize',
        name: 'list.prize',
        component: listPrize,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {

        path: '/list/prize/result',
        name: 'list.prize.result',
        component: listPrizeResult,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {

        path: '/shop/payment',
        name: 'shop.payment',
        component: shopPayment,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {

        path: '/event/promotion',
        name: 'event.promotion',
        component: eventPromotion,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {

        path: '/print/all/point/transaction',
        name: 'print.all.point.transaction',
        component: exportPointTransactionByJob,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {

        path: '/branch/payment',
        name: 'branch.payment',
        component: branchPayment,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {

        path: '/shipment/qty/graph',
        name: 'shipment.qty.graph',
        component: shipmentQtyGraph,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list/insert/shop/invoice',
        name: 'list.insert.shop.invoice',
        component: listForInsertInvoice,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list/shop/invoice',
        name: 'list.shop.invoice',
        component: listShopInvoice,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list/shop/invoice/detail/:id',
        name: 'list.shop.invoice.detail',
        component: listShopInvoiceDetail,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list/branch/summery',
        name: 'list.branch.summery',
        component: listSummaryBranch,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list/lottery/history',
        name: 'list.lottery.history',
        component: listLotteryHistory,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/tab/auto/approved/cod',
        name: 'tab.auto.approved.cod',
        component: tabAutoApproved,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/tab/user/kyc',
        name: 'tab.user.kyc',
        component: tabUserKYC,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list/auto/approved/cod',
        name: 'list.auto.approved.cod',
        component: listAutoApproved,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list/account/bank/',
        name: 'list.account.bank',
        component: listAccountBank,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
//Chat
    {
        path: '/chat',
        name: 'chat',
        component: chat,
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    /**
     * feed back
     */
    {
        path: '/list-feedback',
        name: 'feedback.index',
        component: FeedBack,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list-feedback/:id/views',
        name: 'feedback.view',
        component: viewFeedBackDetail,
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    /**
     * feed back user branch
     */
    {
        path: '/list-feedback-user',
        name: 'feedback-user.index',
        component: () => import('@/views/SuperAdmin/FeedBack/userFeedBack/index'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    {
        path: '/list-feedback-user/:id/view',
        name: 'feedback-user.view',
        component: () => import('@/views/SuperAdmin/FeedBack/userFeedBack/viewFeedBackUserDetail'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },


    /**
     * Parcel Branch
     */
    {
        path: '/list-parcel-branch',
        name: 'parcelBranch.index',
        component: () => import(/* webpackChunkName: "ParcelBranch" */'@/views/SuperAdmin/Parcel/ParcelBranch/Index'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },


    /**
     * Parcel DistributionCenter
     */
    {
        path: '/list-parcel-distribution-center',
        name: 'parcelDistributionCenter.index',
        component: () => import(/* webpackChunkName: "ParcelBranch" */'@/views/SuperAdmin/Parcel/ParcelDistributionCenter/Index'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },


    /**
     * Referral Register
     */
    {
        path: '/list-referral-register',
        name: 'referralRegister.index',
        component: () => import(/* webpackChunkName: "Referral Register" */'@/views/SuperAdmin/ReferalRegister/Index'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    {
        path: '/customer-shipment-total',
        name: 'customer.shipment.total',
        component: CustomerShipmentTotal,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/weekly-data-report',
        name: 'weekly.data.report',
        component: WeeklyDataReport,
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    /**
     * province manager
     */
    {
        path: '/tab-shipment-province-manager',
        name: 'tab.shipment.province.manager',
        component: TabShipmentProvinceManager,
        meta: {
            middleware: [Middlewares.auth],
        }
    },


    /**
     * Route System
     */
    {
        path: '/list-route-system',
        name: 'route.system.index',
        component: () => import(/* webpackChunkName: "Route System Index" */'@/views/SuperAdmin/RouteSystem/ListRouteSystem.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },


    /**
     * Injured Parcel User
     */
    {
        path: '/list-injured-user',
        name: 'injuredParcel.index',
        component: () => import(/* webpackChunkName: "Injured Parcel Index" */'@/views/SuperAdmin/InjuredParcel/ListInjuredParcel.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    /**
     * Injured Parcel Detail
     */
    {
        path: '/injured-user-detail/:id',
        name: 'injuredParcel.detail',
        component: () => import(/* webpackChunkName: "Injured Parcel Detail" */'@/views/SuperAdmin/InjuredParcel/InjuredParcelDetail.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    {
        path: '/injured-user/create/:id',
        name: 'injuredParcel.create',
        component: () => import(/* webpackChunkName: "Injured Parcel Detail" */'@/views/SuperAdmin/InjuredParcel/Modal/Create.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/injured-user/update-installment/:id',
        name: 'update.installment',
        component: () => import(/* webpackChunkName: "Injured Parcel Detail" */'@/views/SuperAdmin/InjuredParcel/Modal/UpdateInstallment.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/injured-user/update/:id',
        name: 'update.injured',
        component: () => import(/* webpackChunkName: "Injured Parcel Detail" */'@/views/SuperAdmin/InjuredParcel/Modal/UpdateInjuredParcel.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    //check parcel Alive
    {
        path: '/parcel-arrived',
        name: 'parcel.arrived.index',
        component: () => import(/* webpackChunkName: "Parcel Alive Index" */'@views/SuperAdmin/CheckParcelArrived/Index.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    /**
     * Vendor Bill
     */
    {
        path: '/vendor-bill',
        name: 'vendor.bill.index',
        component: () => import(/* webpackChunkName: "Injured Parcel Index" */'@/views/SuperAdmin/VendorBill/ListVendorBill.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    /**
     * Shipment bill settlement
     */
    {
        path: '/shipment-bill-settlement',
        name: 'shipment.settlement.index',
        component: () => import(/* webpackChunkName: "ShipmentSettlementIndex" */'@/views/SuperAdmin/ShipmentSettlement/ShipmentSettlement.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    /**
     * Shipment bill cleared
     */
    {
        path: '/shipment-bill-cleared',
        name: 'shipment.cleared.index',
        component: () => import(/* webpackChunkName: "ShipmentClearedIndex" */'@/views/SuperAdmin/ShipmentCleared/ShipmentCleared.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    /**
     * Shipment bill blocked sign
     */
    {
        path: '/shipment-bill-blocked-sign',
        name: 'shipment.blocked.sign.index',
        component: () => import(/* webpackChunkName: "ShipmentBlockedSignIndex" */'@/views/SuperAdmin/ShipmentBlockedSign/ShipmentBlockedSign.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    /**
     * FranchiseOwner
     */
    {
        path: '/franchise/owner',
        name: 'franchise.owner.index',
        component: () => import(/* webpackChunkName: "ShipmentSettlementIndex" */'@/views/SuperAdmin/FranchiseOwner/index.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    /**
     * cod branches balance
     */
    {
        path: '/cod/branches/balance',
        name: 'cod.branches.balance.index',
        component: () => import(/* webpackChunkName: "ShipmentSettlementIndex" */'@/views/SuperAdmin/CodBranchesBalance/index.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },


].concat(branch_accounting);

const router = new VueRouter({
    mode: 'history',
    //base: process.env.BASE_URL,
    routes
});

function checkRoles(to, from, next) {
    const item = window.localStorage.getItem('roleUser');
    const roleUsers = JSON.parse(item);
    let tabQueryInvoice = ''
    /// After everything is ok
    if (roleUsers.includes('accountingmanager') || roleUsers.includes('accountingoriginbranch') || roleUsers.includes('accountingoriginfranchise')) {
        tabQueryInvoice = 'origin_freight'
    } else if (roleUsers.includes('accountingcdc')) {
        tabQueryInvoice = 'destination_freight'
    } else {
        tabQueryInvoice = 'parcel_price'
    }
    if (roleUsers.includes('deliverymanager')) {
        next({name: 'delivery.tab.order.request', query: {tab: 'pending'}});
    } else if (roleUsers.includes('branchmanager') || roleUsers.includes('franchise-manager')) {
        next({name: 'branch.tab.list.order', query: {tab: 'all'}});
    } else if (roleUsers.includes('accountingmanager') || roleUsers.includes('accountingoriginbranch') || roleUsers.includes('accountingoriginfranchise') || roleUsers.includes('accountingcdc') || roleUsers.includes('accountingcod')) {
        if (localStorage.getItem('Lang') === 'CN') {
            next({name: 'account.tab.all.shipments', query: {tab: 'all'}});
        } else {
            next({name: 'account.tab.list.order', query: {tab: tabQueryInvoice}});
        }
    } else if (roleUsers.includes('accounting-local')) {
        next({name: 'tab.invoice.local', query: {tab: 'origin'}});
    } else if (roleUsers.includes('distributioncentermanager')) {
        next({name: 'distribution.center.tab.all.shipments', query: {tab: 'all'}});
    } else if (roleUsers.includes('callcentervipcustomer')) {
        next({name: 'tab.solve.shipments', query: {tab: 'all'}});
    } else if (roleUsers.includes('service')) {
        next({name: 'tab.solve.shipments', query: {tab: 'all'}});
    } else if (roleUsers.includes('distribution')) {
        next({name: 'distribution.center.shipment.bag'});
    } else if (roleUsers.includes('marketing')) {
        next({name: 'marketing.tab.shipment', query: {tab: 'all'}});
    } else if (roleUsers.includes('news')) {
        next({name: 'list.news'});
    } else if (roleUsers.includes('headinternmarketing')) {
        next({name: 'list.intern.marketing'});
    } else if (roleUsers.includes('marketinghelper')) {
        next({name: 'list.shop.item'});
    } else if (roleUsers.includes('suburbMarketing')) {
        next({name: 'marketing.tab.shipment', query: {tab: 'all'}});
    } else if (roleUsers.includes('halcenterlive')) {
        next({name: 'hal.live.coupon'});
    } else if (roleUsers.includes('shop-user') || roleUsers.includes('shop-manager')) {
        next({name: 'shop.payment'});
    } else if (roleUsers.includes('marketing-viewer') || roleUsers.includes('head-franchise')) {
        next({name: 'shipment.qty.graph'});
    } else if (roleUsers.includes('TaxViewer')) {
        next({name: 'shipment.branch.quantities'});
    } else if (roleUsers.includes('chat-admin')) {
        next({name: 'chat'});
    }
    next()
}


function nextCheck(context, middleware, index) {
    const nextMiddleware = middleware[index];

    if (!nextMiddleware) return context.next();

    return (...parameters) => {
        context.next(...parameters);
        const nextMidd = nextCheck(context, middleware, index + 1);

        nextMiddleware({...context, nextMidd});
    }
}

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
        const ctx = {
            from,
            next,
            router,
            to
        }

        const nextMiddleware = nextCheck(ctx, middleware, 1);
        return middleware[0]({...ctx, nextMiddleware});
    }
    return next();
});

export default router
