<script>
import {Bar} from "vue-chartjs";
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

Chart.plugins.register(ChartDataLabels);

export default {
  extends: Bar,
  props: ['chartData', 'report_type'],
  mounted() {
    let message = '';
    if (this.report_type === 'shipment') {
      message = ' ບິນ'
    } else {
      message = ' ກີບ'
    }
    this.renderChart({
      labels: this.chartData.labels,
      datasets: [
        {
          label: (this.report_type === 'shipment') ? 'ຈຳນວນບິນ' : (this.report_type === 'freight') ? 'ຄ່າຂົນສົ່ງ' : 'ຄ່າສິນຄ້າ',
          backgroundColor: (this.report_type === 'shipment') ? '#E11F26' : (this.report_type === 'freight') ? '#2EA8D9' : '#F6CA07',
          data: (this.report_type === 'shipment') ? this.chartData.qty : (this.report_type === 'freight') ? this.chartData.freight : this.chartData.price,
        },
      ]
    }, {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [{
          ticks: {
            callback: (value) => {
              const p = value.toFixed(2).split(".");
              return p[0].split("").reverse().reduce(function (acc, value, i) {
                return value === "-" ? acc : value + (i && !(i % 3) ? "," : "") + acc;
              }, "") + message;
            },
            fontColor: 'black',
            fontFamily: 'Phetsarath OT',
            fontSize: 10
          },
        }],
        xAxes: [{
          ticks: {
            fontColor: 'black',
            fontFamily: 'Phetsarath OT',
            fontSize: 10,
            maxRotation: 90,
            minRotation: 90,
            autoSkip: false,
            autoSkipPadding: 15,
          },
        }]
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: ((tooltipItems) => {
            let p = tooltipItems.yLabel.toFixed(2).split(".");
            return p[0].split("").reverse().reduce(function (acc, value, i) {
              return value === "-" ? acc : value + (i && !(i % 3) ? "," : "") + acc;
            }, "") + message
          })
        },
        titleFontFamily: 'Phetsarath OT',
        footerFontFamily: 'Phetsarath OT',
        bodyFontFamily: 'Phetsarath OT',
      },
      legend: {
        display: false,
      },
      plugins: {
        datalabels: {
          formatter: function (value) {
            let p = value.toFixed(2).split(".");
            return p[0].split("").reverse().reduce(function (acc, value, i) {
              return value === "-" ? acc : value + (i && !(i % 3) ? "," : "") + acc;
            }, "")
          },
          color: "black",
          align: "top",
          offset: 0,
          rotation: -90,
          anchor: "end",
          font: {
            size: 10,
            family: 'Phetsarath OT'
          }
        }
      }
    })
  }
}
</script>

<style scoped>

</style>
